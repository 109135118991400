<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="monthCard-edit">
            <el-form :model="form" ref="form" :rules="rules" label-width="100px" label-position="left" >
                <div class="a-fs-16 a-fw-700 a-flex-rfsc" style="margin-bottom: 16px">
                    <span>基本信息</span>
                </div>
                <el-form-item label="名称" prop="name">
                    <el-input class="inputBox" maxlength="32" clearable v-model="form.name" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="goodsDesc">
                    <el-input
                        type="textarea"
                        :rows="4"
                        maxlength="80"
                        class="inputBox"
                        placeholder="请输入内容"
                        v-model="form.goodsDesc">
                    </el-input>
                </el-form-item>
                <el-form-item label="划线价" prop="oldPrice">
                    <jd-input-price 
                        v-model="form.oldPrice" 
                        style="width:240px"></jd-input-price>
                </el-form-item>
                <el-form-item label="售价" prop="price">
                    <jd-input-price 
                        v-model="form.price" 
                        style="width:240px"></jd-input-price>
                </el-form-item>
                <el-form-item label="状态" prop="goodsStatus">
                    <el-radio-group v-model="form.goodsStatus">
                        <el-radio 
                            :label="1" >上架</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="商品类型" prop="goodsType">
                    <el-select v-model="form.goodsType" placeholder="请选择">
                        <el-option
                            label="神券包"
                            :value="4">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="优惠券选择" prop="relationGoodsId">
                    <div>
                        <div class="a-flex-rfsc a-mb-10">
                            <le-select-remote-search 
                                large
                                class="coupon-item"
                                style="padding: 0 !important;"
                                v-model="form.relationGoodsId" 
                                :options="optionsCoupon" 
                                placeholder="请选择（可输入搜索）" />
                            <span>，数量</span>
                            <el-input 
                                style="width: 160px;margin: 0 10px"
                                clearable 
                                v-model="form.num" 
                                placeholder="请输入" 
                                @input="form.num=form.num.replace(/^(0+)|[^\d]+/g,'')">
                                <template slot="append">张</template>
                            </el-input>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="支付方式" prop="payWay">
                    <el-radio-group v-model="form.payWay">
                        <el-radio 
                            :label="1" >现付</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import util from '../../../src/utils/util'
export default {
    components:{
    },
    data() {
        var checkRelationGoodsId = (rule, value, callback) => {
            if(this.form.goodsType == 4){
                if(!this.form.relationGoodsId) {
                    callback(new Error('请选择优惠券'))
                }else if(!this.form.num) {
                    callback(new Error('请输入优惠券数量'))
                }else{
                    callback()
                }
            }else {
                callback()
            }
        };
        return {
            id: '',
            util: util,
            form:{
                name: '',
                goodsDesc: '',
                oldPrice: '',
                price: '',
                goodsStatus: 1,
                goodsType: 4,
                relationGoodsId: '',
                num: '',
                payWay: 1,
            },
            rules:{
                name: [{required: true, message:'请输入名称', trigger: 'blur'}],
                goodsDesc: [{required: true, message:'请输入描述', trigger: 'blur'}],
                oldPrice: [{required: true, message:'请输入划线价', trigger: 'blur'}],
                price: [{required: true, message:'请输入售价', trigger: 'blur'}],
                relationGoodsId: [{ validator: checkRelationGoodsId, trigger: 'change' }],
            },
            optionsCoupon: {
                url: this.$Config.apiUrl.couponPage,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
                pageSize: 1000
            },
        };
    },
    created () {
        
    },
    
    mounted () {
        this.id = this.$route.query.id?this.$route.query.id:''
        if(this.id) {
            this.getActivityInfo()
        }
    },
    methods: {
        getActivityInfo () {
            
        },
        submit () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        let reqData = {
                            id: this.id,
                            ...this.form,
                            oldPrice: Number((this.form.oldPrice*100).toFixed(0)),
                            price: Number((this.form.price*100).toFixed(0)),
                        }
                        this.$Axios._post({
                            url: this.$Config.apiUrl.addGoods,
                            method: "post",
                            params: reqData
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.$router.back()
                                this.$store.dispatch("delView", this.$route);
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                        .catch(err=>{

                        })                        
                    })
                    .catch(_=>{})
                    
                }
            })
        },
        
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        },
    },
}
</script>

<style lang="scss" scoped>
.monthCard-edit{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
    /deep/ .s-search-label {
		width: 145px !important;
		justify-content: flex-start;
	}
    /deep/ .s-nomarl-input .el-input__inner {
		width: 200px;
	}
    /deep/ .date-range{
        margin: 0;
    }
    /deep/ .projectBox{
        &>div{
            margin: 0;
        }
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
}
.inputBox{
    width: 400px;
}
.addBankBtn{
    padding: 0 20px;
    font-size: 14px;
    color: #007AFF;
    line-height: 34px;
    cursor: pointer;
}
.a-mb-10{
    margin-bottom: 10px;
}
/deep/ .coupon-item{
    &>div{
        margin-left: 0 !important;
    }
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>